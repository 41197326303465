import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { computed, reactive, toRefs, ref, watch } from 'vue';
import Cookies from "js-cookie";
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default {
  // props: ['uploadType','uploadSrc','uploadText'],    // 接收父组件的方法
  props: {
    uploadType: Number,
    uploadSrc: String,
    uploadText: String,
    uploadListType: {
      default: 'picture-card'
    },
    fileType: {
      type: String,
      default: ''
    }
  },
  emits: ['imgUrl'],
  components: {
    LoadingOutlined,
    PlusOutlined
  },

  setup(props, context) {
    //图片上传
    const fileList = ref([]);
    const imageUrl = ref('');
    const loading = ref(false);
    const uploadData = ref({
      type: null
    });

    const handleChange = info => {
      // console.log(info)
      if (info.file.status === 'uploading') {
        loading.value = true;
        return;
      }

      if (info.file.status === 'done') {
        // Get this url from response in real world.
        // getBase64(info.file.originFileObj, base64Url => {
        //   console.log('base64Url',base64Url)
        //   imageUrl.value = base64Url;
        //   props.uploadSrc = `/personnelapi/image/${base64Url}`
        //   console.log(props.uploadSrc)
        //   loading.value = false;
        // });
        if (info.file.response.code == 200) {
          imageUrl.value = `/personnelapi/image/${info.file.response.data}`;
          loading.value = false;
          context.emit('imgUrl', info.file.response.data);
        } else {
          _message.error(info.file.response.message);

          loading.value = false;
        }
      }

      if (info.file.status === 'error') {
        loading.value = false;

        _message.error('上传失败');
      }
    };

    const uploadHeaders = computed(() => ({
      'token': Cookies.get('token'),
      'belongProject': Cookies.get('belongProject')
    }));

    const beforeUpload = file => {
      //1，2，3为图片 4为文件
      uploadData.value = {
        type: props.uploadType
      };
      let isJpgOrPng = true;

      if (props.fileType == "") {
        isJpgOrPng = file.type === 'image/jpeg';
      } else {
        isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      }

      if (props.fileType == "" && !isJpgOrPng) {
        _message.error('只能上传JPG文件!');

        return false;
      }

      if (props.fileType !== "" && !isJpgOrPng) {
        _message.error('只能上传JPG和PNG文件!');

        return false;
      }

      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        _message.error('图片大小不能大于2MB!');

        return false;
      }

      return isJpgOrPng && isLt2M;
    };

    watch(() => props.uploadSrc, () => {
      console.log(props.uploadSrc);

      if (!props.uploadSrc) {
        imageUrl.value = '';
      }
    });
    return {
      handleChange,
      beforeUpload,
      fileList,
      imageUrl,
      loading,
      uploadHeaders,
      uploadData
    };
  }

};