import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted } from 'vue';
import { PlusOutlined, LoadingOutlined, DownOutlined } from '@ant-design/icons-vue';
import useTablePagination from '@/hooks/pagination';
import { selectVisitorsByPagination, insertHyVisitors, updateHyVisitors, selectIdCardMessage, selectVisitorsList } from '/src/api/personnel';
import moment from 'moment';
import upload from '@/components/upload.vue';
import getFieldRules from '@/utils/fieldRules';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
} //表头


const columns = [{
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '身份证号',
  dataIndex: 'IDnum'
}, {
  title: '手机号',
  dataIndex: 'phoneNumber'
}, {
  title: '访问期限',
  dataIndex: 'accessTime'
}, {
  title: '访问记录',
  dataIndex: 'isArrived',
  slots: {
    customRender: 'isArrived'
  }
}, {
  title: '与面板机是否同步',
  dataIndex: 'isSynchronization',
  slots: {
    customRender: 'isSynchronization'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
const listColumns = [{
  title: '序号',
  dataIndex: 'num'
}, {
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '来访时间',
  dataIndex: 'firstTime'
}, {
  title: '离开时间',
  dataIndex: 'lastTime'
}];
export default defineComponent({
  setup() {
    onMounted(() => {
      selectVisitorsByPagination1();
    });

    const disabledDate = current => {
      // Can not select days before today and today
      return current && current < moment().endOf('day') - 8.64e7;
    };

    let tableDataList = ref([]); ///////////////

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current; //判断当前是否为筛选查询

      if (isFilter) {
        onSubmit();
      } else {
        selectVisitorsByPagination1();
      }
    };

    const isFilter = ref(false);
    const tableLoading = ref(false);
    const btnLoading = ref(false); //表格数据

    let tableData = ref([]);
    const formRef = ref();
    const visible = ref(false);
    const visibleDetail = ref(false);
    const listVisible = ref(false);
    const isChange = ref(false);

    const handleCancel = () => {
      visible.value = false;
      formRef.value.resetFields();
      isChange.value = false;
      Object.assign(newState, {
        name: '',
        IDNumber: '',
        phone: '',
        dateScope: [],
        cardFront: '',
        cardBack: '',
        icon: ''
      });
    };

    const handleCancelList = () => {
      listVisible.value = false;
    };

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = () => {
      formRef.value.validate().then(() => {
        if (newState.icon === '') {
          _message.error('请上传头像');

          return;
        }

        let date = ['', ''];

        if (typeof newState.dateScope[0] == 'string') {
          date[0] = newState.dateScope[0];
        } else if (newState.dateScope[0] != undefined) {
          date[0] = newState.dateScope[0].format('YYYY-MM-DD HH:mm:ss');
        }

        if (typeof newState.dateScope[1] == 'string') {
          date[1] = newState.dateScope[1];
        } else if (newState.dateScope[1] != undefined) {
          date[1] = newState.dateScope[1].format('YYYY-MM-DD HH:mm:ss');
        }

        let addState = {
          visitorName: newState.name,
          visitorIdcard: newState.IDNumber,
          visitorMobile: newState.phone,
          visitorStartTime: date[0],
          visitorEndTime: date[1],
          visitorIcon: newState.icon,
          cardFront: newState.cardFront,
          cardBack: newState.cardBack,
          visitorNo: newState.visitorNo,
          uid: newState.uid
        };

        if (isChange.value) {
          addState.visitorNo = newState.visitorNo;
          updateHyVisitors1(addState);
        } else {
          insertHyVisitors1(addState);
        }
      }).catch(error => {
        console.log('error', error);
      });
    };

    const formState = reactive({
      name: '',
      IDNumber: '',
      phone: '',
      date1: [],
      isSynchronization: null
    });
    const newState = reactive({
      name: '',
      IDNumber: '',
      phone: '',
      dateScope: [],
      cardFront: '',
      cardBack: '',
      icon: '',
      uid: ''
    }); //访客详情数据

    const detailState = reactive({
      name: 'ttt',
      IDNumber: '12345',
      phone: '13212232345',
      dateScope: '2021-01-01至2021-02-03',
      IDImg: '',
      IDImg1: '',
      photo: '',
      uid: ''
    });
    const {
      checkName,
      checkCard,
      checkPhone
    } = getFieldRules();
    const rules = {
      name: [{
        required: true,
        validator: checkName,
        trigger: 'blur'
      }],
      IDNumber: [{
        required: true,
        validator: checkCard,
        trigger: 'blur'
      }],
      phone: [{
        required: true,
        validator: checkPhone,
        trigger: 'blur'
      }],
      dateScope: [{
        required: true,
        trigger: 'change',
        type: 'array'
      }]
    };

    const onSubmit = () => {
      let date = ['', ''];

      if (formState.date1[0] != undefined) {
        date[0] = formState.date1[0].format('YYYY-MM-DD HH:mm:ss');
      }

      if (formState.date1[1] != undefined) {
        date[1] = formState.date1[1].format('YYYY-MM-DD HH:mm:ss');
      }

      let submitData = {
        visitorName: formState.name,
        visitorIdcard: formState.IDNumber,
        visitorMobile: formState.phone,
        visitorStartTime: date[0],
        visitorEndTime: date[1],
        isSynchronization: parseInt(formState.isSynchronization),
        pageNumber: current.value == 0 ? '1' : current.value,
        limit: pageSize.value == 0 ? '10' : pageSize.value
      };
      filterVisitorsByPagination1(submitData);
    };

    const reset = () => {
      btnLoading.value = false;
      Object.assign(formState, {
        name: '',
        IDNumber: '',
        phone: '',
        date1: []
      });
      current.value = '1';
      pageSize.value = '10';
      selectVisitorsByPagination1();
    }; //图片上传


    const getImgUrlIcon = params => {
      newState.icon = params;
    };

    const getImgUrlCardFront = params => {
      newState.cardFront = params;
    };

    const getImgUrlCardBack = params => {
      newState.cardBack = params;
    }; //查询访客列表


    const selectVisitorsByPagination1 = async () => {
      tableLoading.value = true;
      pageSize.value = pageSize.value == 0 ? '10' : pageSize.value;
      current.value = current.value == 0 ? '1' : current.value;
      let res = await selectVisitorsByPagination({
        pageNumber: current.value == 0 ? '1' : current.value,
        limit: pageSize.value == 0 ? '10' : pageSize.value
      });

      if (res.code === 200) {
        tableLoading.value = false;
        let data = res.data;
        let list = res.data.list;
        current.value = data.nowPage;
        pageSize.value = data.pageSize;
        total.value = data.countSize;
        isFilter.value = false;
        tableData.value = [];

        if (list != null) {
          list.filter((item, index) => {
            tableData.value.push({
              key: index + 1,
              name: item.visitorName,
              IDnum: item.visitorIdcard,
              phoneNumber: item.visitorMobile,
              accessTime: item.visitorStartTime + '至' + item.visitorEndTime,
              cardFront: item.cardFront,
              cardBack: item.cardBack,
              visitorIcon: item.visitorIcon,
              isSynchronization: item.isSynchronization,
              visitorStartTime: item.visitorStartTime,
              visitorEndTime: item.visitorEndTime,
              visitorNo: item.visitorNo,
              isArrived: item.isArrived,
              userNo: item.visitorNo,
              belongProject: item.belongProject,
              uid: item.uid
            });
          });
        }
      } else {
        tableLoading.value = false;

        _message.error(res.message);
      }
    }; //筛选访客列表


    const filterVisitorsByPagination1 = async val => {
      tableLoading.value = true;
      let res = await selectVisitorsByPagination(val);

      if (res.code === 200) {
        tableLoading.value = false;
        let data = res.data;
        let list = res.data.list;
        current.value = data.nowPage;
        pageSize.value = data.pageSize;
        total.value = data.countSize;
        isFilter.value = true;
        tableData.value = [];

        if (list != null) {
          list.filter((item, index) => {
            tableData.value.push({
              key: index + 1,
              name: item.visitorName,
              IDnum: item.visitorIdcard,
              phoneNumber: item.visitorMobile,
              accessTime: item.visitorStartTime + '至' + item.visitorEndTime,
              cardFront: item.cardFront,
              cardBack: item.cardBack,
              visitorIcon: item.visitorIcon,
              isSynchronization: item.isSynchronization,
              visitorStartTime: item.visitorStartTime,
              visitorEndTime: item.visitorEndTime,
              visitorNo: item.visitorNo,
              isArrived: item.isArrived,
              userNo: item.userNo,
              belongProject: item.belongProject,
              uid: item.uid
            });
          });
        }
      } else {
        tableLoading.value = false;

        _message.error(res.message);
      }
    }; //查询访客详情


    const detailVisitors = record => {
      visibleDetail.value = true;
      Object.assign(detailState, {
        name: record.name,
        IDNumber: record.IDnum,
        phone: record.phoneNumber,
        dateScope: record.accessTime,
        IDImg: record.cardFront,
        IDImg1: record.cardBack,
        photo: record.visitorIcon,
        uid: record.uid
      });
    }; //修改访客


    const changeVisitors = record => {
      visible.value = true;
      isChange.value = true;
      console.log(record);
      Object.assign(newState, {
        name: record.name,
        IDNumber: record.IDnum,
        phone: record.phoneNumber,
        dateScope: [record.visitorStartTime, record.visitorEndTime],
        cardFront: record.cardFront,
        cardBack: record.cardBack,
        icon: record.visitorIcon,
        visitorNo: record.visitorNo,
        uid: record.uid
      });
    }; //修改访客接口


    const updateHyVisitors1 = async val => {
      btnLoading.value = true;
      let res = await updateHyVisitors(val);

      if (res.code === 200) {
        _message.success(res.message);

        reset();
        visible.value = false;
        Object.assign(newState, {
          name: '',
          IDNumber: '',
          phone: '',
          dateScope: [],
          cardFront: '',
          cardBack: '',
          icon: ''
        });
      } else if (res.code === 10000) {
        _message.warning(res.message);

        reset();
        visible.value = false;
        Object.assign(newState, {
          name: '',
          IDNumber: '',
          phone: '',
          dateScope: [],
          cardFront: '',
          cardBack: '',
          icon: ''
        });
      } else {
        btnLoading.value = false;

        _message.error(res.message);
      }
    }; //添加访客


    const insertHyVisitors1 = async val => {
      btnLoading.value = true;
      let res = await insertHyVisitors(val);

      if (res.code === 200) {
        _message.success(res.message);

        reset();
        visible.value = false;
        Object.assign(newState, {
          name: '',
          IDNumber: '',
          phone: '',
          dateScope: [],
          cardFront: '',
          cardBack: '',
          icon: ''
        });
      } else if (res.code === 10000) {
        _message.warning(res.message);

        reset();
        visible.value = false;
        Object.assign(newState, {
          name: '',
          IDNumber: '',
          phone: '',
          dateScope: [],
          cardFront: '',
          cardBack: '',
          icon: ''
        });
      } else {
        btnLoading.value = false;

        _message.error(res.message);
      }
    }; //通过身份证上传


    const cardFrontImgUrl1 = idCardUrl => {
      if (idCardUrl) {
        getIdCardMessage(idCardUrl);
      }
    };

    const getIdCardMessage = async idCardUrl => {
      const res = await selectIdCardMessage({
        idCardUrl
      });

      if (res.code === 200) {
        Object.assign(newState, {
          name: res.data.userName,
          IDNumber: res.data.idCard,
          phone: '',
          dateScope: [],
          cardFront: idCardUrl,
          cardBack: '',
          icon: ''
        });
        showModal();
      } else {
        _message.error(res.message);
      }
    }; //查看访问记录


    const selectVisitorsList1 = async record => {
      let param = {
        userNo: record.uid,
        belongProject: record.belongProject,
        visitorStartTime: record.visitorStartTime,
        visitorEndTime: record.visitorEndTime
      };
      let res = await selectVisitorsList(param);

      if (res.code === 200) {
        listVisible.value = true;
        let list = res.data;
        tableDataList.value = [];

        if (list != null) {
          list.filter((item, index) => {
            tableDataList.value.push({
              num: index + 1,
              name: item.userName,
              firstTime: item.firstTime,
              lastTime: item.lastTime
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    return {
      visible,
      visibleDetail,
      formRef,
      formState,
      newState,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 18
      },
      rules,
      onSubmit,
      tableData,
      columns,
      showModal,
      handleOk,
      paginationConfig,
      tableLoading,
      btnLoading,
      onPageChange,
      reset,
      detailState,
      detailVisitors,
      upload,
      getImgUrlIcon,
      getImgUrlCardFront,
      getImgUrlCardBack,
      handleCancel,
      changeVisitors,
      isChange,
      cardFrontImgUrl1,
      selectVisitorsList1,
      listVisible,
      handleCancelList,
      listColumns,
      tableDataList,
      disabledDate
    };
  },

  components: {
    LoadingOutlined,
    PlusOutlined,
    upload,
    DownOutlined
  }
});