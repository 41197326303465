import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-d8858902"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "upload-box"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  class: "ant-upload-text"
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  class: "ant-upload-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_loading_outlined = _resolveComponent("loading-outlined");

  const _component_plus_outlined = _resolveComponent("plus-outlined");

  const _component_a_upload = _resolveComponent("a-upload");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_a_upload, {
    "file-list": $setup.fileList,
    "onUpdate:file-list": _cache[0] || (_cache[0] = $event => $setup.fileList = $event),
    name: "files",
    "list-type": $props.uploadListType,
    class: "avatar-uploader",
    "show-upload-list": false,
    action: "/personnelapi/HyVisitors/uploadBatch",
    "before-upload": $setup.beforeUpload,
    onChange: $setup.handleChange,
    data: $setup.uploadData,
    headers: $setup.uploadHeaders
  }, {
    default: _withCtx(() => [$props.uploadListType === 'picture-card' ? (_openBlock(), _createElementBlock("div", _hoisted_2, [$setup.loading ? (_openBlock(), _createBlock(_component_loading_outlined, {
      key: 0
    })) : (_openBlock(), _createBlock(_component_plus_outlined, {
      key: 1
    })), _createElementVNode("div", _hoisted_3, _toDisplayString($props.uploadText), 1)])) : _createCommentVNode("", true), $props.uploadListType !== 'picture-card' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString($props.uploadText), 1)])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["file-list", "list-type", "before-upload", "onChange", "data", "headers"])]);
}