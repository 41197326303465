/*
 * 人员管理接口列表
 */

import axios from '@/utils/http'; // 导入http中创建的axios实例
import instance from '@/utils/http';
instance.defaults.timeout = 1000 * 55;

/**
 * 查询黑名单列表
 */
export const selectBlacklistByPagination = (param) => {
  return axios.post(`/personnelapi/HyBlacklist/selectBlacklistByPagination`, param);
}

/**
 * 通过身份证查询人员信息
 */
export const getEmployee = (param) => {
  return axios.post(`/personnelapi/employee/selectEmployeePro`, param);
}

/**
 * 黑名单人员所在项目集合
 */
export const selectHyBlacklistPro = (param) => {
  return axios.post(`/personnelapi/HyBlacklist/selectHyBlacklistPro`, param);
}

/**
 * 添加黑名单
 */
export const insertHyBlacklist = (param) => {
  return axios.post(`/personnelapi/HyBlacklist/insertHyBlacklist`, param);
}

/**
 * 移除黑名单
 */
export const deleteHyBlacklist = (param) => {
  return axios.post(`/personnelapi/HyBlacklist/deleteHyBlacklist`, param);
}

//人员列表
export const selectEmployeeByPagination = (param) => {
  return axios.post(`/wlapi/employee/selectEmployeeByPagination`, param);
}

//岗位查询
export const selectJobByParam = (param) => {
  return axios.post(`/personnelapi/HyJob/selectJobByParam`, param);
}

//访客查询
export const selectVisitorsByPagination = (param) => {
  return axios.post(`/personnelapi/HyVisitors/selectVisitorsByPagination`, param);
}

//新增人员
export const insertHyEmployee = (param) => {
  return axios.post(`/wlapi/employee/insertHyEmployee`, param);
}

//查看人员详情
export const selectEmployee = (param) => {
  return axios.post(`/wlapi/employee/selectEmployee`, param);
}

//查询白名单
export const selectWhiteListSet = (param) => {
  return axios.post(`/wlapi/employee/selectWhiteListSet`, param);
}

//删除人员
export const deleteHyEmployee = (param) => {
  return axios.post(`/wlapi/employee/deleteHyEmployee`, param);
}

//设置白名单
export const updateWhiteList = (param) => {
  return axios.post(`/wlapi/employee/updateWhiteList`, param);
}

//添加访客
export const insertHyVisitors = (param) => {
  return axios.post(`/personnelapi/HyVisitors/insertHyVisitors`, param);
}
//修改访客
export const updateHyVisitors = (param) => {
  return axios.post(`/personnelapi/HyVisitors/updateHyVisitors`, param);
}
//查看访问记录
export const selectVisitorsList = (param) => {
  return axios.post(`/personnelapi/HyVisitors/selectVisitorsList`, param);
}

//修改人员信息
export const updateHyEmployee = (param) => {
  return axios.post(`/wlapi/employee/updateHyEmployee`, param);
}
//从离职人员库搜索人员信息
export const selectDepartEmployee = (param) => {
  return axios.post(`/wlapi/employee/selectDepartEmployee`, param);
}

//获取身份证信息
export const selectIdCardMessage = (param) => {
  return axios.post(`/personnelapi/HyVisitors/selectIdCardMessage`, param);
}
//重置员工密码
export const updateResetPwd = (param) => {
  return axios.post(`/wlapi/employee/updateResetPwd`, param);
}
//员工是否需要交接
export const isNeedHandOver = (param) => {
  return axios.post(`/wlapi/employee/isNeedHandOver`, param);
}

//查询所有部门
export const selectDepartmentList = (param) => {
  return axios.post(`/personnelapi/knDepartment/selectDepartmentList`, param);
}
//查询所有职级
export const selectRankList = (param) => {
  return axios.post(`/personnelapi/knRank/selectRankList`, param);
}
//查询所有工作地点
export const selectWorkPlaceByList = (param) => {
  return axios.post(`/personnelapi/knWorkPlace/selectWorkPlaceByList`, param);
}
//查询上级
export const isSuperiorList = (param) => {
  return axios.post(`/personnelapi/employee/isSuperiorList`, param);
}
//新建部门信息
export const insertDepartment = (param) => {
  return axios.post(`/personnelapi/knDepartment/insertDepartment`, param);
}
//修改部门信息
export const updateDepartment = (param) => {
  return axios.post(`/personnelapi/knDepartment/updateDepartment`, param);
}
//删除部门信息
export const deleteDepartment = (param) => {
  return axios.post(`/personnelapi/knDepartment/deleteDepartment`, param);
}
//部门信息列表
export const selectDepartmentByPagination = (param) => {
  return axios.post(`/personnelapi/knDepartment/selectDepartmentByPagination`, param);
}
//新建职级信息
export const insertRank = (param) => {
  return axios.post(`/personnelapi/knRank/insertRank`, param);
}
//修改职级信息
export const updateRank = (param) => {
  return axios.post(`/personnelapi/knRank/updateRank`, param);
}
//删除职级信息
export const deleteRank = (param) => {
  return axios.post(`/personnelapi/knRank/deleteRank`, param);
}
//查询职级信息列表
export const selectRankByPagination = (param) => {
  return axios.post(`/personnelapi/knRank/selectRankByPagination`, param);
}
//新建工作地点信息
export const insertWorkPlace = (param) => {
  return axios.post(`/personnelapi/knWorkPlace/insertWorkPlace`, param);
}
//修改工作地点信息
export const updateWorkPlace = (param) => {
  return axios.post(`/personnelapi/knWorkPlace/updateWorkPlace`, param);
}
//删除工作地点信息
export const deleteWorkPlace = (param) => {
  return axios.post(`/personnelapi/knWorkPlace/deleteWorkPlace`, param);
}
//查询工作地点信息列表
export const selectWorkPlaceByPagination = (param) => {
  return axios.post(`/personnelapi/knWorkPlace/selectWorkPlaceByPagination`, param);
}
//查询岗位列表
export const selectJobByPagination = (param) => {
  return axios.post(`/personnelapi/HyJob/selectJobByPagination`, param);
}
//查询组织架构
export const selectOrganization = (param) => {
  return axios.post(`/personnelapi/employee/selectOrganization`, param);
}
//查询我的假期
export const selectMyHolidayRecord = (param) => {
  return axios.post(`/wlapi/knHolidayRecord/selectMyHolidayRecord`, param);
}
//添加历史职位信息
export const insertHistoryPosition = (param) => {
  return axios.post(`/personnelapi/knHistoryPosition/insertHistoryPosition`, param);
}
//修改历史职位信息
export const updateHistoryPosition = (param) => {
  return axios.post(`/personnelapi/knHistoryPosition/updateHistoryPosition`, param);
}
//删除历史职位信息
export const deleteHistoryPosition = (param) => {
  return axios.post(`/personnelapi/knHistoryPosition/deleteHistoryPosition`, param);
}
//查询历史职位集合
export const selectHistoryPositionList = (param) => {
  return axios.post(`/personnelapi/knHistoryPosition/selectHistoryPositionList`, param);
}

//查询工资单
export const selectPayrollByPagination = (param) => {
  return axios.post(`/wlapi/knPayroll/selectPayrollByPagination`, param);
}
// 删除工资单接口
export const deleteHyPayroll = (param) => {
  return axios.post(`/wlapi/knPayroll/deleteHyPayroll`, param);
}
//查询工资单详情
export const selectPayrollDetailByPagination = (param) => {
  return axios.post(`/wlapi/knPayroll/selectPayrollDetailByPagination`, param);
}
//查询工资单明细详情
export const selectPayrollDetailById = (param) => {
  return axios.get(`/wlapi/knPayroll/selectPayrollDetailById?id=${param}`);
}
//工资单发送/撤回接口
export const updateSendOrWithdrawn = (param) => {
  return axios.post(`/wlapi/knPayroll/updateSendOrWithdrawn`, param);
}
//工资单项添加或修改接口
export const addOrUpdateSalaryItem = (param) => {
  return axios.post(`/wlapi/knSalaryItem/addOrUpdateSalaryItem`, param);
}
//工资单项详情接口
export const selectSalaryItem = (param) => {
  return axios.get(`/wlapi/knSalaryItem/selectSalaryItemList`);
}
//删除工资单项接口
export const deleteItemById = (param) => {
  return axios.post(`/wlapi/knSalaryItem/deleteItemById`, param);
}
//添加工资单接口
export const insertPayroll = (param) => {
  return axios.post(`/wlapi/knPayroll/insertPayroll`, param);
}
//解析工资单数据
export const readPayrollExcel = (param) => {
  return axios.post(`/wlapi/knPayroll/readPayrollExcel`, param);
}
//查询考核规则
export const selectPerformanceRule = (param) => {
  return axios.post(`/personnelapi/knPerformanceRule/selectPerformanceRule`, param);
}
//添加或修改绩效考核规则
export const addOrUpdatePerformanceRule = (param) => {
  return axios.post(`/personnelapi/knPerformanceRule/addOrUpdatePerformanceRule`, param);
}
//开启或关闭考核
export const setPerformance = (param) => {
  return axios.post(`/personnelapi/knPerformanceRule/setPerformance`, param);
}
//设置绩效考核提示时间
export const setPerformanceTime = (param) => {
  return axios.post(`/personnelapi/knPerformanceRule/setPerformanceTime`, param);
}
//查询绩效考核提示相关时间
export const selectPerformanceTime = (param) => {
  return axios.post(`/personnelapi/knPerformanceRule/selectPerformanceTime`, param);
}
//分页查询考核列表
export const selectPerformanceByPagination = (param) => {
  return axios.post(`/personnelapi/knPerformance/selectPerformanceByPagination`, param);
}
//绩效考核详情
export const selectPerformanceById = (param) => {
  return axios.post(`/personnelapi/knPerformance/selectPerformanceById`, param);
}
//审批绩效考核
export const updateToPerformance = (param) => {
  return axios.post(`/personnelapi/knPerformance/updateToPerformance`, param);
}
//财务确认绩效考核通过
export const financeConfirmPerformance = (param) => {
  return axios.post(`/personnelapi/knPerformance/financeConfirmPerformance`, param);
}
//查询我的绩效列表
export const selectMyPerformanceByPagination = (param) => {
  return axios.post(`/personnelapi/knPerformance/selectMyPerformanceByPagination`, param);
}
//发起绩效考核
export const insertPerformance = (param) => {
  return axios.post(`/personnelapi/knPerformance/insertPerformance`, param);
}
//修改绩效考核
export const updatePerformance = (param) => {
  return axios.post(`/personnelapi/knPerformance/updatePerformance`, param);
}
//我的工资单
export const selectMyPayrollByPagination = (param) => {
  return axios.post(`/wlapi/knPayroll/selectMyPayrollByPagination`, param);
}
//查询历史绩效明细接口
export const selectHistoryPerformanceInfo = (param) => {
  return axios.post(`/wlapi/knPerformance/selectHistoryPerformanceInfo`, param);
}